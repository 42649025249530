@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins/breakpoints";

/*============================
	COLOR Pale Golden Rod
==============================*/

$primary-color: 			#dddddd;
$primary-color-hover: 		darken($primary-color, 14%);
$primary-gradient: linear-gradient(to right bottom, #a51ce5, #ff009e, #ff4452, #ffa500, #ebeb12);


#header {
  .navbar {
    padding: 0px;
    transition:  0.32s ease-in-out;
  }

}

.sectionoverlap {
  margin-bottom: -7vh;
  z-index: 2;
}

a, a:focus {
  color: $primary-color;
}
a:hover, a:active {
    color: $primary-color-hover;

}

.gradient-icons a:hover {
  background: $primary-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.social-icons li:hover.social-icons-facebook a, .social-icons li:hover.social-icons-twitter a, .social-icons li:hover.social-icons-instagram a, .social-icons li:hover.social-icons-linkedin a   {
  color: #e9ecef;
}

.primary-menu {
  ul.navbar-nav > li {
	&.dropdown {
      .dropdown-menu li {
        &:hover > a:not(.btn) {
          color: $primary-color;
          background: $primary-gradient;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  &.navbar-line-under-text ul.navbar-nav > li {
	> a:not(.btn):after {
		border-color: $primary-color;
    background: $primary-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
		}
	}
}

.navbar-expand-lg ul.navbar-nav li:hover > a:not(.btn), .navbar-expand-lg ul.navbar-nav li > a.active:not(.btn) {
  background: $primary-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.subtitle {
  font-size: 40px;
  background: $primary-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position:relative; 
}

.shaddowletter {
    position: relative;
    text-transform: uppercase;
  
    text-shadow: $box-shadow;
    text-shadow: -15px 5px 20px rgba($black, .175);
  
    color: white;
    letter-spacing: -0.08em;
    font-family: 'Anton', Arial, sans-serif;
    user-select: none;
    text-transform: uppercase;
    font-size: calc(100% + 8vw + 5vh);
    transition: all 0.25s ease-out;
  }

.gradient-icon {
  font-size: 16px;
  background: $primary-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-mask{
  background: $primary-gradient;
  


}




// For Responsive Navbar
@mixin navbar-responsive {
ul.navbar-nav {
  li {
	&:hover > a:not(.btn), & > a.active:not(.btn) {
	  color: $primary-color;
    background: $primary-gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    }
  }  
}
}

.navbar-expand-none{
	@include navbar-responsive;
}

@include media-breakpoint-down(xs) {
	.navbar-expand-sm{
		@include navbar-responsive;
	}
}

@include media-breakpoint-down(sm) {
	.navbar-expand-md{
		@include navbar-responsive;
	}
}

@include media-breakpoint-down(md) {
	.navbar-expand-lg{
		@include navbar-responsive;
	}
}
@include media-breakpoint-down(lg) {
	.navbar-expand-xl{
		@include navbar-responsive;
	}
}

/*== Secondary Nav ==*/
.secondary-nav.nav {
  &.alternate {
    .nav-item .nav-link.active {
      border-color: $primary-color;
    }
  }
}

/* Page Header */
.page-header {
  &.page-header-text-dark {
    .breadcrumb > li {
      a {
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}

/* Accordion */
.accordion {
  &:not(.accordion-flush) .accordion-header .accordion-button {
    background-color: $primary-color;
  }
}

/* Nav */

.nav-tabs {
  .nav-item .nav-link {
    &.active {
      &:after {
        background-color: $primary-color;
      }
    }
  }
}
.nav-pills .nav-link:not(.active):hover{color: $primary-color;}

/* Brands Grid */
.brands-grid {
  > .row > div a {
    &:hover {
	  color:$primary-color;
    }
  }
}

#footer {
  .nav {
    .nav-item {
      .nav-link {
        &:focus {
          color: $primary-color;
        }
      }
    }
    .nav-link:hover {
      color: $primary-color;
    }
  }
  .footer-copyright {
    .nav {
      .nav-link:hover {
        color: $primary-color;
      }
    }
  }
}

/* Back to Top */
#back-to-top {
  &:hover {
    background-color: $primary-color;
  }
}

/* Extras */
.bg-primary, .badge-primary {
	background-color: $primary-color !important;
}
.text-primary{
	color: $primary-color !important;
}

.btn-link{
	color: $primary-color;
}

.btn-link:hover {
	color: $primary-color-hover !important;
}

.text-muted{
	color:#8e9a9d!important;
}
.text-light{
	color:#dee3e4!important;
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover{
	background-color: $primary-color-hover!important;
}
.border-primary {
	border-color: $primary-color !important;
}

.btn-primary{
  background-color: $primary-color;
  border-color: $primary-color;
  &:hover {
    background-color: $primary-color-hover;
    border-color: $primary-color-hover;
  }
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active{
  background-color: $primary-color-hover;
  border-color: $primary-color-hover;
  
}

.btn-primary.focus, .btn-primary:focus{
	background-color: $primary-color-hover;
    border-color: $primary-color-hover;
}

.btn-outline-primary, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active{
  color: $primary-color;
  border-color: $primary-color;
  &:hover {
    background-color: $primary-color;
    border-color: $primary-color;
	color: #fff;
  }
}

.progress-bar,
.nav-pills .nav-link.active, .nav-pills .show > .nav-link, .dropdown-item.active, .dropdown-item:active{
	background-color: $primary-color;
}
.page-item.active .page-link,
.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label:before{
	background-color: $primary-color;
	border-color: $primary-color;
}
.list-group-item.active{
	background-color: $primary-color;
	border-color: $primary-color;
}
.page-link {
  color: $primary-color;
  &:hover {
    color: $primary-color-hover;
  }
}

.slick-dots{
	li{
		&.slick-active button{
		  border-color: $primary-color;
		}
		&.slick-active button:before, button:hover:before{
			background-color: $primary-color;
		}
	}
}


/*=== 5.9 Portfolio ===*/

.portfolio {
  .portfolio-box {
    position: relative;
    // border-radius: 22px;
    overflow: hidden;
    .portfolio-overlay {
      text-align: center;
      display: -ms-flexbox !important;
      display: flex !important;
      position: absolute;
      left: 0;
      right: 0;
      top: 65%;
      bottom: 0;
      opacity: 0;
      background: none;
      //background: $primary-gradient;

    }
    &:hover .portfolio-overlay {
      opacity: 1;
      top: 0;
    }
    .portfolio-img img {
      transition: 1.3s ease-in-out;
	}
  &:hover .portfolio-img img {
    -webkit-filter: grayscale(1) brightness(0.5) blur(2px);
    filter: grayscale(1) brightness(0.5) blur(2px);  
  }


  }


}


/** Dot Menu **/ 

.navbar-expand-none.navbar-text-light .navbar-collapse {
  background: none;
}

.nav-tgl {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  z-index: 1002;
  right: 30px;
  top: 30px;
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 50%;
  padding: 0;
  background: #fff;
	box-shadow: 0px 4px 24px rgba(#000, 0.24);
  line-height: 0.6;
  text-align: center;
	
	// making the dividers
	> span {
		// the second divider
		display: inline-block;
		position: relative;
		height: 2px;
		width: 34px;
		border-radius: 1px;
		background: #293335;
		vertical-align: middle;
		
		// the first & the third dividers
		&:before, &:after {
		  display: inline-block;
			position: absolute;
			content: "";
			height: 2px;
			border-radius: 1px;
			background: #293335;
			// for the hover state
			transition: all 200ms;
		}
		&:before {
		  top: -11px;
			left: 3px;
			width: 28px;
		}
		&:after {
		  top: 11px;
			left: 6px;
			width: 22px;
		}
	}
	
	// ofcorse we should find a replacement for the focus state but it's not our topic
	&:focus {outline: none}
	
	&:hover > span:after, &:hover > span:before {
		width: 34px;
		left: 0;
	}
}

// for the nav background (styling the nav itself is not our topic)
.nav:before {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  transition: all 500ms ease-in-out;
	
	// that's all the pen about
  clip-path: circle(30px at calc(100% - 78px) 60px);
	// for AT
  visibility: hidden;
}

// when it gits activated
.menu.active {
	.nav:before {
		visibility: visible;

		// that's all the pen about
		clip-path: circle(100%);
	}
	
	.nav-tgl > span {
		height: 0;
		&:after, &:before {
		  top: 0px;
			left: 0;
			width: 34px;
		}
		&:after {
		  transform: rotate(-45deg);
		}
		&:before {
			transform: rotate(45deg);
		}
	}
}



